<template>
    <div id="app_user_list">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="add()" size="mini">
                    添加
                </el-button>
            </div>
            <div class="head_top_title">通道配置</div>

        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row type="flex" align="middle"  style="padding:10px 0;">
                <el-input  v-model="searchID" @change="submit_cha_xun()" placeholder="请输入通道id或通道名称" prefix-icon="el-icon-search" size="small"></el-input>
                <el-button type="primary"  plain size="mini" style="margin-left:20px;" @click="submit_cha_xun()">查询</el-button>
            </el-row>


            <el-row style="overflow: auto;" :style="{height: (content_heigth+'px')}">
                <template v-for="item in tableData">
                    <el-row type="flex" align="middle" @click.native="detail(item.id)" style="border-bottom:1px solid #DCDFE6;padding:5px 0px;">
                        <el-col :span="18">
                            <!-- 通道id+公司名 -->
                            <div style="line-height:25px">
                                {{item.id}}<br>
                                <span style="color:gray;">{{item.channel_name}}</span>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <el-row type="flex" justify="end" align="middle" style="margin-right:10px;">
                                <i class="el-icon-arrow-right" style="font-size:20px;"></i>
                            </el-row>
                        </el-col>
                    </el-row>

                </template>
            </el-row>

        </el-row>

        <!-- 客通道增加或修改组件 -->
        <ChannelAddUpd :prop_change_i="prop_channel_add_i"
            :prop_item="prop_channel_add_obj"
            @result="addUpdResult"
        ></ChannelAddUpd>

        
        
    </div>
</template>
<script>
import router from '../../router/route.js'
import API from '../../api/api';
import ChannelAddUpd from '../channel/channel_add_upd.vue';//组件 通道增加或修改

export default {

    components: {
       ChannelAddUpd
    },
    
    data(){
        return{
            isCollapse:true,

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            searchID:"",//搜索条件
            tableData: [],//列表数据

            //通道增加
            prop_channel_add_i:0,//弹出框是否可见
            prop_channel_add_obj:{},
            
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        // this.searchID = this.$options.data().searchID;//单个恢复初始化
        // this.tableData = this.$options.data().tableData;//单个恢复初始化

        //重置所有数据
        Object.assign(this.$data, this.$options.data.call(this));
        //初始化设置
        this.initSetUp();
        
    },

    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 185);
        },

        //输入值发送变化时请求接口
        submit_cha_xun() {
            //请求接口
            API.ChannelServlet({
                param: "listAISelect",
                search:this.searchID,
                style:-1,//通道属性 -2:包括国内国际全部,-1:国内全部 0:移动 1：联通 2：电信
                send_type:-1,//通道发送短信的类型 -1:所有 1：验证码 2：行业通知 3：营销 4：四类
                onoff:-1,//状态 -1：全部 0：关闭 1：打开
                agreement:-1,//协议类型 -1:全部0：无相关协议；1：http1.0;2:http2.0;3:助通协议;101:cmpp2.0 102:cmpp3.0 103:SGIP1.0 104:SGIP1.2 105:SMGP
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;
                } 
            });
        },
        //通道详情
        detail(id){
            console.log("跳转到通道详情");
            API.router_to("/channel_detail/"+id);
        },
        //通道增加
        add(){
            this.prop_channel_add_i ++;////弹出框是否可见
            this.prop_channel_add_obj.type = "add";
        },
        addUpdResult(){

        },

        //返回
        goBack() {
            router.push("/channel_index");
        }
    }
};

</script>

<style scoped>
/* #app_user_list >>>.el-divider--horizontal{
    margin: 4px 0;
} */
</style>